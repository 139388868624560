







































































































































































































































































































































































































































































import Vue from 'vue';
import { CurrencyType, User, VetAnimalType } from '@/models/user.model';
import { VetSkillType } from '@/models/position.model';
import { Practice } from '@/models/practice.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import constants from '@/constants/constants';
import router from '@/router';
import { db } from '@/main';
import { Place } from '@/models/place.model';
import {
  ContractType,
  PerkType,
  WorkConditionsType
} from '@/models/position.model';

export default Vue.extend({
  name: 'Register',
  components: {
    PlaceSearch: () => import('@/components/PlaceSearch.vue')
  },
  data() {
    return {
      isBusy: false,
      step: 1,
      user: {} as User,
      practice: {} as Practice,
      password: '',
      showPassword: false,
      rules: {
        required: (value: string) => !!value || 'Required.',
        min: (value: string) => value.length >= 6 || 'Min 6 characters',
        email: (value: string) =>
          /.+@.+\..+/.test(value) || 'E-mail must be valid',
        mobile: (value: string) =>
          /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(
            value
          ) || 'Number must be valid',
        year: (value: string) =>
          value?.length === 4 || 'Please enter a four digit year',
        animalPreferences: (value: string[]) =>
          value?.length > 0 || 'Please select at least one animal type',
        preferredContractTypes: (value: string[]) =>
          value?.length > 0 ||
          'Please select at least one preferred contract type',
        preferredPerkTypes: (value: string[]) =>
          value?.length > 0 || 'Please select at least one preferred perk'
      },
      isEmailFormValid: false,
      isDetailsFormValid: false,
      isAcademicDetailsFormValid: false,
      isPreferencesFormValid: false,
      isSkillsFormValid: false,
      isSalaryFormValid: false,
      isPracticeDetailsFormValid: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      placesSearchResults: [],
      placesService: {} as any,
      practiceAddressText: '',
      userAddressText: '',
      registrationType: this.$route.query.registrationType,
      academicInstitutions: constants.ACADEMIC_INSTITUTIONS.names,
      accreditationInstitutions: constants.ACCREDITATION_INSTITUTIONS.names,
      vetAnimalTypes: Object.values(VetAnimalType),
      workConditionTypes: Object.values(WorkConditionsType),
      vetSkillTypes: Object.values(VetSkillType),
      currencyTypes: Object.values(CurrencyType),
      contractTypes: Object.values(ContractType),
      perkTypes: Object.values(PerkType),
      vetSkillType: VetSkillType,
      currency: CurrencyType.usd,
      hasVerifiedEmail: false,
      userPlace: undefined,
      practicePlace: undefined,
      selectedVetSkills: [] as VetSkillType[],
      isMobileNumberValid: false
    };
  },
  methods: {
    async createUser() {
      this.isBusy = true;
      await firebase
        .auth()
        .createUserWithEmailAndPassword(this.user.email, this.password)
        .then(() => {
          this.showSnackbar('User created successfuly.', 'success');
          this.sendVerificationEmail();
          this.step = 2;
        })
        .catch(error => {
          this.isBusy = false;
          console.error(error);
          this.showSnackbar(error, 'error');
        });
    },
    async sendVerificationEmail() {
      const user = firebase.auth().currentUser;
      await user
        ?.sendEmailVerification()
        .then(() => {
          this.isBusy = false;
          this.showSnackbar('Verification email sent.', 'success');
        })
        .catch(error => {
          this.isBusy = false;
          console.error(error);
          this.showSnackbar(error, 'error');
        });
    },
    async hasVerifiedEmailCheck() {
      this.isBusy = true;
      const user = firebase.auth().currentUser;
      await user?.reload();
      if (user?.emailVerified) {
        this.isBusy = false;
        this.hasVerifiedEmail = true;
        this.showSnackbar('Email verified successfully.', 'success');
        const authListener = firebase
          .auth()
          .onAuthStateChanged(async fbUser => {
            if (fbUser) {
              this.user.uid = fbUser?.uid;
              db
                .collection('users')
                .doc(this.user.uid)
                .set(this.user, { merge: true }),
                this.$store.commit('updateFirebaseUser', fbUser);
              authListener();
            }
          });
        this.step = 3;
      } else {
        this.isBusy = false;
        this.showSnackbar(
          'Email not verified successfully please try again.',
          'error'
        );
      }
    },
    noEmailReceived() {
      this.$confirm('Have you checked your spam folder?').then(res => {
        if (res) {
          this.$confirm(`Is ${this.user.email} your correct email?`).then(
            res => {
              if (res) {
                this.$confirm(
                  `Please contact support at support@guavavet.com`,
                  { buttonTrueText: 'Email Support', buttonFalseText: 'Cancel' }
                ).then(() => {
                  window.open(
                    `mailto:support@guavavet.com?subject=Cannot verify email&body=My email is ${this.user.email} and I do not receive a verification email. Please assist me.`
                  );
                });
              } else {
                this.step = 1;
              }
            }
          );
        }
      });
    },
    hasSelectedAddress() {
      if (
        (this.user.city && this.user.googlePlaceId) ||
        this.registrationType === 'practice'
      ) {
        this.updateUser();
        this.step = 4;
      } else {
        this.showSnackbar(
          'Please select a valid address from the dropdown',
          'error'
        );
      }
    },
    complete() {
      if (!this.isEmailFormValid) {
        this.step = 1;
        return;
      }
      if (!this.hasVerifiedEmail) {
        this.step = 2;
        return;
      }
      if (!this.isDetailsFormValid) {
        this.step = 3;
        return;
      }
      if (!this.isAcademicDetailsFormValid && this.registrationType === 'vet') {
        this.step = 4;
        return;
      }
      if (!this.isPreferencesFormValid && this.registrationType === 'vet') {
        this.step = 5;
        return;
      }
      if (!this.isSkillsFormValid && this.registrationType === 'vet') {
        this.step = 6;
        return;
      }
      if (!this.isSalaryFormValid && this.registrationType === 'vet') {
        this.step = 7;
        return;
      }
      router.push({ name: 'Home' });
    },
    updateUser() {
      if (this.registrationType === 'vet') {
        this.user.isCandidate = true;
        this.user.isApproved = false;
      }
      if (this.selectedVetSkills) {
        this.user.vetSkillTypes = this.selectedVetSkills;
      }
      if (!this.user.workConditionTypes) {
        this.user.workConditionTypes = [];
      }
      db.collection('users')
        .doc(this.user.uid)
        .set(this.user, { merge: true })
        .then(() => console.log('user successfully updated'))
        .catch(error => console.error(error));
      this.$store.commit('updateCurrentUser', this.user);
    },
    salarySelected() {
      this.user.currency = this.currency;
      this.updateUser();
    },
    userPlaceSelected(place: Place) {
      this.user.googlePlaceId = place.googlePlaceId;
      this.user.address = place.address;
      this.user.city = place.city;
      this.user.country = place.country;
      this.user.geoPoint = place.geoPoint;
    },
    practicePlaceSelected(place: Place) {
      this.practice.googlePlaceId = place.googlePlaceId;
      this.practice.address = place.address;
      this.practice.city = place.city;
      this.practice.country = place.country;
      this.practice.geoPoint = place.geoPoint;
      if (place.rating) {
        this.practice.googleRating = place.rating;
        this.practice.numberOfGoogleRatings = place.numberOfRatings;
      }
    },
    addPractice() {
      this.practice.uid = db.collection('practices').doc().id;
      this.practice.userUids = [this.user.uid];
      db.collection('practices')
        .doc(this.practice.uid)
        .set(this.practice, { merge: true })
        .then(() => console.log('practice successfully updated'))
        .catch(error => console.error(error));
      this.user.practiceUids = [this.practice.uid];
      this.user.activePractice = {
        name: this.practice.name,
        uid: this.practice.uid
      };
      this.updateUser();
    },
    onUserMobileNumberInput(formattedNumber, { number }) {
      this.user.mobileNumber = number.international;
    },
    onUserPracticeMobileNumberInput(formattedNumber, { number }) {
      this.practice.mobileNumber = number.international;
    },
    onPracticePhoneNumberInput(formattedNumber, { number }) {
      this.practice.phoneNumber = number.international;
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  }
});
